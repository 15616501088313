<template>
    <b-card no-body class="p-1">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <div class="demo-spacing-0 d-flex justify-content-between m-1" dir="rtl">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    <feather-icon size="16" icon="FilterIcon"/>
                    فیلتر
                </b-button>
                <!--                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info">-->
                <!--                            <feather-icon size="16" icon="UserPlusIcon"/>-->
                <!--                            افزودن کیف پول-->
                <!--                        </b-button>-->
            </div>

            <div class="demo-spacing-0 d-flex justify-content-between m-1" dir="rtl">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info"
                          @click="updateStatusFromExchange">
                    بروزرسانی وضعیت
                    ({{ lastUpdate ? $G2J(lastUpdate) : 'به روز نیست' }})
                </b-button>
            </div>

            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"
            />
            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        @sort-dailyChanged="sort($event)"
                        no-local-sort
                        class="text-nowrap"
                        style="min-height : 235px"
                >
                    <template #cell(marketType)="data">
                        <b-media vertical-align="center">
                            <!--              <template #aside>-->
                            <!--                <b-avatar-->
                            <!--                    size="32"-->
                            <!--                    :src="require(`@/assets/images/Coins/${data.item.destinationCoin}.png`)"-->
                            <!--                    :variant="`light-info`"-->
                            <!--                    :to="{ name: 'Markets', params: { id: data.item.id } }"-->
                            <!--                />-->
                            <!--                <span class="align-self-center px-25"> | </span>-->
                            <!--                <b-avatar-->
                            <!--                    size="32"-->
                            <!--                    :src="require(`@/assets/images/Coins/${data.item.sourceCoin}.png`)"-->
                            <!--                    :variant="`light-info`"-->
                            <!--                    :to="{ name: 'Markets', params: { id: data.item.id } }"-->
                            <!--                />-->
                            <!--              </template>-->
                            <b-link
                                    :to="{ name: 'Markets', params: { id: data.item.id } }"
                                    class="font-weight-bold d-block text-nowrap"
                            >
                                <b-avatar
                                        size="22"
                                        class="my-50"
                                        :src="data.item.coin.logo"
                                        v-tooltip="data.item.coin.label"
                                        :variant="`light-info`"
                                />
                                /
                                <b-avatar
                                        size="22"
                                        class="my-50"
                                        :src="data.item.quote.logo"
                                        v-tooltip="data.item.quote.label"
                                        :variant="`light-info`"
                                />
                            </b-link>
                            <small class="text-muted">{{ data.item.marketType }}</small>
                        </b-media>
                    </template>

                    <template #cell(customMinAmount)="{item}">
                        <table-field-base :item="item"
                                          key-name="custom_min_amount"
                                          tooltip="اصلاح مقدار"
                                          :address="'/markets/' + item.id"
                                          method="patch"
                        />
                    </template>

                    <template #cell(customMaxAmount)="{item}">
                        <table-field-base :item="item"
                                          key-name="custom_max_amount"
                                          tooltip="اصلاح مقدار"
                                          :address="'/markets/' + item.id"
                                          method="patch"
                        />
                    </template>

                    <template #cell(status)="data">
                        <b-badge
                                pill
                                :variant="'light-'+activeVariant(data.item.status)"
                                class="text-capitalize"
                        >
                            {{ activeLabel(data.item.status) }}
                        </b-badge>
                    </template>

                    <template #cell(action)="{item, index}">
                        <template
                                v-if="$havePermission('DETAILS_MARKETS') ||
                         $havePermission('UPDATE_MARKETS') ||
                          $havePermission('LIST_ORDERS') ||
                           $havePermission('LIST_TRADES')"
                        >
                            <b-dropdown
                                    id="dropdown-offset"
                                    variant="link"
                                    no-caret
                                    offset="80px"
                                    :right="true"
                            >
                                <template #button-content>
                                    <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item v-if="$havePermission('DETAILS_MARKET')"
                                                 :to="{ name: 'statistics', params: { id: item.name } }">
                                    <feather-icon icon="TrendingUpIcon"/>
                                    <span class="align-middle ml-50">نمودار ها</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('UPDATE_MARKET')"
                                                 @click="changeStatus(index)">
                                    <feather-icon icon="RefreshCcwIcon"/>
                                    <span class="align-middle ml-50">تغییر وضعیت</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('LIST_ORDERS')"
                                                 :to="{ name: 'Orders', query: { MARKET_TYPE: item.marketType } }">
                                    <feather-icon icon="ShoppingBagIcon"/>
                                    <span class="align-middle ml-50">سفارشات</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('LIST_TRADES')"
                                                 :to="{ name: 'Deals', query: { MARKET_TYPE: item.marketType } }">
                                    <feather-icon icon="BriefcaseIcon"/>
                                    <span class="align-middle ml-50">معاملات</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                        <not-allowed v-else/>
                    </template>

                </b-table>
            </div>
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>
                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
import {
    BPagination,
    BCard,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    BMedia,
    BAvatar,
    BOverlay,
    BLink,
} from 'bootstrap-vue'
import NotAllowed from "@/layouts/components/NotAllowed";
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';
import TableFieldBase from "@/Components/TableFieldBase";

export default {
    name: 'Wallets',
    components: {
        TableFieldBase,
        NotAllowed,
        SearchAndFilter,
        BPagination,
        BCard,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BBadge,
        BMedia,
        BAvatar,
        BOverlay,
        BLink,
        vSelect,
    },
    data: () => ({
        test: 0,
        pageLength: 3,
        dir: false,
        searchTerm: '',
        currentPage: 1,
        perPage: 10,
        rows: 200,
        sortBy: '',
        isSortDirDesc: '',
        userData: [],
        isActive: false,
        perPageOptions: [5, 10, 20, 50, 100],
        items: [],
        lastUpdate: false,
        columns: [
            {
                label: 'مارکت',
                key: 'marketType',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'حداقل سفارش (بایننس)',
                key: 'min_amount',
            },
            {
                label: 'حداکثر سفارش (بایننس)',
                key: 'max_amount',
            },
            {
                label: 'حداقل سفارش (قابل تغییر)',
                key: 'customMinAmount',
            },
            {
                label: 'حداکثر سفارش (قابل تغییر)',
                key: 'customMaxAmount',
            },
            {
                label: 'وضعیت',
                key: 'status',
                sortable: false,
                searchType: 'select',
                selectOptions: [
                    {label: 'غیر فعال', value: 'DISABLED'},
                    {label: 'غیرفعال (بایننس)', value: 'DISABLED_BY_BINANCE'},
                    {label: 'فعال', value: 'ACTIVATED'}
                ]
            },
            {
                label: 'جزییات',
                key: 'action'
            },
        ],
    }),
    computed: {
        activeVariant() {
            return e => (e === 'ACTIVATED') ? 'success' : 'danger';
        },
        activeLabel() {
            return e => {
                switch (e) {
                    case 'ACTIVATED':
                        return 'فعال'
                    case 'DISABLED':
                        return 'غیر فعال'
                    case 'DISABLED_BY_BINANCE':
                        return 'غیر فعال (بایننس)'
                }
            };
        },
        dailyChangeVariant() {
            return e => e < 0 ? 'danger' : e > 0 ? 'success' : 'secondary';
        },
        dailyChangeIcon() {
            return e => e < 0 ? 'ArrowDownCircleIcon' : e > 0 ? 'ArrowUpCircleIcon' : 'CircleIcon';
        },
        coinUnit() {
            const a = {
                TOMAN: 'ریال',
                BITCOIN: 'BTC',
                BITCOIN_CASH: 'BCH',
                ETHEREUM: 'ETH',
                CLASSIC_ETHEREUM: 'ETC',
                LITE_COIN: 'LTC',
                BINANCE_COIN: 'BNB',
                EOS: 'EOS',
                STELLAR: 'XLM',
                RIPPLE: 'XRP',
                TRON: 'TRX',
                DASH: 'DASH',
                TETHER: 'USDT',
            }
            return e => a[e];
        },
    },
    methods: {
        async changeStatus(e) {
            let item = this.items[e]
            this.$swal({
                title: 'آیا از تغییر وضعیت بازار مطمئن هستید؟',
                // text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'بله',
                cancelButtonText: 'خیر',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(async (result) => {
                if (result.value) {
                    this.state.loading = true
                    let address = '/markets/' + item.id
                    const res = await this.$axios.patch(address, {status: item.status === 'ACTIVATED' ? 'DISABLED' : 'ACTIVATED'})
                    this.state.loading = false

                    this.items[e].status = res.data.data.status

                    this.$swal({
                        icon: 'success',
                        title: this.items[e].status === 'ACTIVATED' ? 'بازار فعال شد' : 'بازار غیر فعال شد',
                        confirmButtonText: 'تایید',
                        // text: 'Your file has been deleted.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                }
            })
        },
        async getData(page, perPage) {
            this.state.loading = true
            const queryParams = {
                size: perPage,
                page: page,
                ...this.$route.query
            }
            const res = await this.$axios('/markets', {params: queryParams})
            this.state.loading = false
            this.items = res.data.data
            this.currentPage = res.data.meta.current_page
            this.rows = res.data.meta.total
        },
        async updateStatusFromExchange() {
            await this.$axios.post('/exchange/config')

            await this.getData(this.currentPage, this.perPage);
            await this.getLastUpdate()
        },
        async getLastUpdate() {
            try {
                const res = await this.$axios.get('/configs/SCHEDULE/EXCHANGE_INFO')
                this.lastUpdate = res.data.data.value
            } catch (e) {
                e
            }
        }
    },
    async mounted() {
        await this.getData()
        await  this.getLastUpdate()

    },

}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
